import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import HeroBox from './HeroBox'
import '../../node_modules/pannellum/build/pannellum.css';

const DEFAULT_MAX_TEXTURE_SIZE = 4096
const PREFERRED_TEXTURE_SIZE = 8192

function Panorama(props) {
  //console.log('Panorama', props)

  const decideSuitablePanoSize = () => {
    // Find out how big a panorama the client can handle
    const canvas = document.createElement('canvas')
    const gl = canvas.getContext('experimental-webgl')
    const maxSize = gl.getParameter(gl.MAX_TEXTURE_SIZE)
    //console.log('maxTextureSize', maxSize)

    if (maxSize < PREFERRED_TEXTURE_SIZE) {
      setPanoSource(sizes[DEFAULT_MAX_TEXTURE_SIZE])
    } else {
      setPanoSource(sizes[PREFERRED_TEXTURE_SIZE])
    }

    // Cleanup
    const extension = gl.getExtension('WEBGL_lose_context');
    if (extension) {
      extension.loseContext();
    }
  }

  // Sizes is a stringified object provided by gatsby-remark-mdx-images plugin: 
  // "{4096: '/static/123abc.jpg', 8192: '/static/456cdf.jpg'}"
  let sizes = props.sizes
  if (typeof props.sizes === 'string') {
    sizes = JSON.parse(props.sizes)
  }

  const [panoSource, setPanoSource] = useState()

  // This runs when component is mounted
  useEffect(decideSuitablePanoSize)

  return panoSource ? <BasePanorama panorama={panoSource} {...props} /> : null
}

Panorama.defaultProps = {
  // Options: https://pannellum.org/documentation/reference/
  autoLoad: true,
  type: 'equirectangular',
  autoRotate: -1,

  // Don't interrupt page scrolling when hovering the panorama 
  mouseZoom: 'fullscreenonly',

  // Parascope style defaults
  hfov: 80,
  maxHfov: 80,
  minHfov: 60,
  vaov: 110,
  minPitch: -50,
  maxPitch: 50,
}

Panorama.propTypes = {
  source: PropTypes.string.isRequired,
  sizes: PropTypes.string.isRequired,
}

export default Panorama

// Private component to hold the pannellum instance
class BasePanorama extends Component {
  componentDidMount() {
    require('pannellum')
    window.pannellum.viewer(this.container, {
      ...this.props
    });
  }

  render() {
    // Used to calculate aspect ratio for responsive panorama
    const width = 1024
    const height = 448
    
    return (
      <HeroBox>
        <div
          className="panorama"
          ref={el => this.container = el}
          style={{
            position: 'relative',
            paddingBottom: `${(height / width) * 100}%`,
            height: 0,
            overflow: 'hidden',
          }}
        />
      </HeroBox>
    );
  }
}
