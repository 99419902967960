import React from 'react'
import PropTypes from 'prop-types'

// These exports are used in the MDX files
export const YoutubeVideo = props => <Video service="youtube" {...props} />
export const VimeoVideo = props => <Video service="vimeo" {...props} />

// Responsive base video 
const Video = props => {
  // Default ratio is 4:3
  const width = parseInt(props.width) || 4
  const height = parseInt(props.height) || 3

  let src, className;
  switch (props.service) {
    case 'youtube':
      src = `https://www.youtube.com/embed/${props.id}`
      break;
    case 'vimeo':
      src = `https://player.vimeo.com/video/${props.id}?color=ce0058&title=0&byline=0&portrait=0`
  }

  return (
    <div className="video">
      <div
        className="iframe-wrapper"
        style={{
          paddingBottom: `${(height / width) * 100}%`,
          position: 'relative',
          height: 0,
          overflow: 'hidden',
        }}
      >
        <iframe
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          src={src}
          width={`100%`}
          height={`100%`}
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </div>
  )
}

Video.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}


