module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/plugins/gatsby-remark-mdx-image/index.js"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1380,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-smartypants"}],"globalScope":"\n        import BlogPosts from '$components/BlogPosts'\n        import Gallery from '$components/Gallery'\n        import HeroBox from '$components/HeroBox'\n        import Image from '$components/Image'\n        import Item from '$components/GalleryItem'\n        import Intro from '$components/Intro'\n        import PulloutBox from '$components/PulloutBox'\n        import SpeechBubble from '$components/SpeechBubble'\n        import SpeechBubbleCloud from '$components/SpeechBubbleCloud'\n        import Sound from '$components/Sound'\n        import SuperheroBox from '$components/SuperheroBox'\n        import { VimeoVideo, YoutubeVideo } from '$components/Video'\n\n        export default { BlogPosts, Gallery, HeroBox, Image, Item, Intro, VimeoVideo, PulloutBox, SpeechBubble, SpeechBubbleCloud, Sound, SuperheroBox, YoutubeVideo }\n      "},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
