import React from 'react'
import PropTypes from 'prop-types'

const SpeechBubble = ({ children, speaker, variation }) => {
  const variationClassName = variation ? `variation-${variation}` : ''

  return (
    <>
      <div className={`speech-bubble ${variationClassName}`}>
        {children}
      </div>
      <cite>{speaker}</cite>
    </>
  )
}

SpeechBubble.defaultProps = {
  speaker: 'Unknown',
  variation: 'one',
}

SpeechBubble.propTypes = {
  speaker: PropTypes.string,
  variation: PropTypes.string,
}

export default SpeechBubble
