
        import BlogPosts from '$components/BlogPosts'
        import Gallery from '$components/Gallery'
        import HeroBox from '$components/HeroBox'
        import Image from '$components/Image'
        import Item from '$components/GalleryItem'
        import Intro from '$components/Intro'
        import PulloutBox from '$components/PulloutBox'
        import SpeechBubble from '$components/SpeechBubble'
        import SpeechBubbleCloud from '$components/SpeechBubbleCloud'
        import Sound from '$components/Sound'
        import SuperheroBox from '$components/SuperheroBox'
        import { VimeoVideo, YoutubeVideo } from '$components/Video'

        export default { BlogPosts, Gallery, HeroBox, Image, Item, Intro, VimeoVideo, PulloutBox, SpeechBubble, SpeechBubbleCloud, Sound, SuperheroBox, YoutubeVideo }
      