import React, { useState, useEffect } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import Link from './Link'
import Image from './Image'

// Using 1.0 alpha of React Images
// https://deploy-preview-212--react-images.netlify.com/#/
export default function Gallery(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalCurrentIndex, setModalCurrentIndex] = useState(0)

  const openModal = (imageIndex) => {
    setModalCurrentIndex(imageIndex)
    setModalIsOpen(true)
  }

  // TODO: Now we assume all items are images, but they could be videos
  const items = React.Children.toArray(props.children).map(item => item.props)

  return (
    <>
      <div className="gallery">
        <div className="gallery-grid">
        {items.map((item, i) => {
          if (item && item.type === 'image') {
            return (
              <div className="gallery-thumbnail" key={item.source} onClick={() => openModal(i)}>
                <Image data={item.data} title={item.title} />
              </div>
            )
          } else if (item && item.type === 'video') {
            return (
              <div className="gallery-thumbnail" key={item.id}>
                <p>Video: {item.id} ({item.title})</p>
              </div>
            )
          } else {
            console.log(`Gallery - Invalid item ${i}: ${items}`)
            return null
          }
        })}
        </div>
      </div>

    {typeof window !== 'undefined' &&
      <Lightbox isOpen={modalIsOpen} index={modalCurrentIndex} images={items} onClose={() => setModalIsOpen(false)} />
    }
    </>
  )
}

// Lightbox modal showing gatsby images
const Lightbox = props => {
  const { isOpen, index, images, onClose } = props
  const closeModal = () => setModalIsOpen(false)

  return (
    <ModalGateway>
      {isOpen && (
        <Modal
          onClose={onClose}
          styles={{
            blanket: base => ({
              ...base,
              backgroundColor: 'rgba(16,11,0,0.95)',
              zIndex: 9000,
            }),
            dialog: base => ({ ...base, /*width: '100%'*/ }),
            positioner: base => ({ ...base, zIndex: 9001 }),
          }}
        >
          <Carousel
            views={images} 
            frameProps={{ autoSize: 'height' }}
            currentIndex={index}
            components={{
              FooterCaption,
              View: ItemView,
              FooterCount: () => null, // Don't show "1 of 10 images"
            }}
            styles={{
              header: base => ({
                ...base,
                zIndex: 10000,
              }),
              footer: base => ({
                ...base,
                width: '100%',
                paddingBottom: '1rem',
                //position: 'static',
              }),
            }}
          />
        </Modal>
      )}
    </ModalGateway>
  )
}

// Custom view for the lightbox content"
// We show a responsive gatsby-image
// https://github.com/jossmac/react-images/blob/v1/src/components/View.js
const ItemView = props => {
  //console.log('ItemView', props.currentView)
  return (
    <div className="gallery-item">
      <Image data={props.currentView.data} title={props.currentView.title} />
    </div>
  )
}

// Lightbox footer caption
const FooterCaption = props => {
  const { currentView, isModal } = props
  const { caption, title } = currentView
  //const { caption, author } = currentView;
  return (
    <div className="foooter-caption">
      <span>
        <span className="title" >
          {title}
        </span>
      {caption && 
        <span className="caption">
          &nbsp;— {caption}
        </span>
      }
      </span>
    </div>
  )
}


  /*
  const carouselStyles  = {
    footer: base => ({
      ...base,
      background: 'none !important',
      color: '#666',
      padding: 0,
      paddingTop: 20,
      position: 'static',
      '& a': { color: 'black' },
    }),
    header: base => ({
      ...base,
      background: 'none !important',
      padding: 0,
      paddingBottom: 10,
      position: 'static',
    }),
    headerClose: base => ({
      ...base,
      color: '#666',
      ':hover': { color: '#DE350B' },
    }),
    view: base => ({
      ...base,
      overflow: 'hidden',
      objectFit: 'contain',
      '& > img': {
        maxHeight: '75vh',
        height: 'auto',
        width: 'auto',
        margin: '0 auto',
      },
    }),
  }
  */


