import React from 'react'
import PropTypes from 'prop-types'
import Image from './Image'
import { VimeoVideo } from './Video'

// Video

const VideoItem = props => {
  // TODO: Support videos other than Vimeo
  return <VimeoVideo {...props} />
}

VideoItem.propTypes = {
  id: PropTypes.string.isRequired,
}

// Image

const ImageItem = props => {
  //console.log('ImageItem props', props)
  const title = props.caption
    ? `${props.title}\n\n${props.caption}`
    : props.title ? props.title : ''
  
  return (
    <Image
      alt={props.title}
      data={props.data}
      title={title}
    />
  )

  return (
    <figure key={new Date()} className="item">
      <Image
        source={props.source}
        alt={`Image: ${props.title}`}
        title={`${props.title} | ${props.caption}`}
        {...props}
      />
      <figcaption>
        <strong>{`${props.title}`}</strong>
        <br />
        <span>{`${props.caption}`}</span>
      </figcaption>
    </figure>
  )
}

ImageItem.propTypes = {
  source: PropTypes.string.isRequired,
  title: PropTypes.string,
  caption: PropTypes.string,
}

// Base Component

const GalleryItem = props => {
  // TEMP
  //return <div className="item">it</div>

  switch (props.type) {
    case 'image':
      return <ImageItem {...props} />
    //return <p>GalleryItem</p>
    case 'video':
      return <VideoItem {...props} />
  }
}

GalleryItem.defaultProps = {
  type: 'image',
}

GalleryItem.propTypes = {
  type: PropTypes.string.isRequired,
}

export default GalleryItem
