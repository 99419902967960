import React from 'react'
import PropTypes from 'prop-types'
import Link from './Link'

const Card = props => {
  const { size, link, children } = props
  const cardClass = size ? `card-${size}` : 'card-medium'

  if (link) {
    return (
      <Link className={cardClass} to={link}>
        {children}
      </Link>
    )
  } else {
    return (
      // ...props is used here by BucketList
      <div className={cardClass} {...props}>
        {children}
      </div>
    )
  }
}

Card.propTypes = {
  size: PropTypes.string,
  link: PropTypes.string,
}

export default Card
