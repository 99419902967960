import React from 'react'
import PropTypes from 'prop-types'
import GatsbyImage from 'gatsby-image'

const Image = props => {
  //console.log('Image props', props)
  const { data, source } = props

  const alt = props.alt || props.title || props.source || 'Unsworn image'
  const title = props.title || props.alt || ''

  // TODO: handle source

  let fluid = props.fluid

  // TODO: remove this
  if (typeof props.fluid === 'string') {
    fluid = JSON.parse(props.fluid)
  }

  if (typeof props.data === 'string') {
    fluid = JSON.parse(props.data)
  }

  if (data && data.childImageSharp && data.childImageSharp.fluid) {
    fluid = data.childImageSharp.fluid
  }

  // TODO: pass on the rest of the props, such as style?
  if (fluid) {
    // Image processed by Gatsby
    return <GatsbyImage fluid={fluid} alt={alt} title={title} />
  } else if (source) {
    // Just an image
    return <img src={source} alt={alt} title={title} />
  } else if (data && (data.extension === 'gif' || data.extension === 'svg')) {
    // Gif or SVG
    return <img src={data.publicURL} alt={alt} title={title} />
  } else {
    // Something's wrong
    console.warn(`<Image /> | Image is not valid`, props)
    return null
  }
}

Image.propTypes = {
  alt: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string, // JSON-string from plugin
    PropTypes.shape({
      childImageSharp: PropTypes.any, // fluid | fixed
    }),
  ]),
  source: PropTypes.string,
  title: PropTypes.string,
}

export default Image

