import React from 'react'
import PropTypes from 'prop-types'
import Image from './Image'
import Cartotheque from './Cartotheque'
import Card from './Card'
import Img from 'gatsby-image'

const BlogPosts = ({ posts }) => {
  //console.log('BlogPosts', posts)

  if (!posts) {
    return null
  }
  return (
    <Cartotheque>
      {posts.map(({ frontmatter: post }) => {
        const link = `/blog/${post.year}/${post.slug}`
        return (
          <Card key={post.slug} link={link} size="card-small">
            <Image data={post.image} />
            <h3>{`${post.year}: ${post.title}`}</h3>
          </Card>
        )
      })}
    </Cartotheque>
  )
}

BlogPosts.propTypes = {
  posts: PropTypes.array.isRequired,
}

export default BlogPosts
