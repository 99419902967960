import React from 'react'
import PropTypes from 'prop-types'

function isAbsolute(url) {
  return url.indexOf('http') === 0
}

const Sound = props => {
  //const url = isAbsolute(source) ? source : `${IMGIX_URL_BASE}${source}`;
  //console.log('Sound', props)

  return <audio src={props.source} controls />
}

Sound.propTypes = {
  source: PropTypes.string.isRequired,
  title: PropTypes.string, // TODO: Display track info?
}

export default Sound
